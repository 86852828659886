import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "main-check-box",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCheckBox()))
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      class: "main-check-box__input",
      checked: _ctx.modelValue,
      style: _normalizeStyle({ width: _ctx.width, height: _ctx.height })
    }, null, 12, _hoisted_1),
    _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
  ]))
}